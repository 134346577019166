<template>
  <div
    v-if="!state.decisionGiven"
    class="bg-faded w-full h-full absolute flex flex-col justify-end items-center"
  >
    <div class="w-3/5 dialog-bg p-5 rounded-xl h-32 flex justify-evenly mb-4">
      <!-- Buttons when Leader and Advisor have the same choice -->
      <Button
        v-if="sameAnswer"
        class="mr-3 font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-red-600"
        @click="handleReject()"
        @touchstart.prevent="handleReject()"
      >
        {{ t('chooseagain') }}
      </Button>

      <Button
        v-if="sameAnswer"
        class="mr-3 font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-green-500"
        @click="handleConfirm()"
        @touchstart.prevent="handleConfirm()"
      >
        {{ t('confirmchoice') }}
      </Button>

      <!-- Buttons when Leader and Advisor have different choices -->
      <Button
        v-if="!sameAnswer"
        class="mr-3 font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-blue-500"
        @click="handleAcceptAdvice()"
        @touchstart.prevent="handleAcceptAdvice()"
      >
        {{ t('acceptadvice') }}
      </Button>
      <Button
        v-if="!sameAnswer"
        class="font-bold rounded-3xl border-none"
        textcolour="text-white"
        backgroundcolour="bg-green-500"
        @click="handleAcceptFirstDecision()"
        @touchstart.prevent="handleAcceptFirstDecision()"
      >
        {{ t('acceptfirstdecision') }}
      </Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed } from 'vue'
import { useI18n } from 'vue-i18n'

import { FinalDecisionStatus} from '@/constants'
import Button from '@/components/base/Button.vue'
import useMultiPlayerState from '@/composition/useMultiplayerState'

const multiplayer = useMultiPlayerState()

const { t } = useI18n()

const state = reactive({
  decisionGiven: false
})

onMounted(() => {
  state.decisionGiven = false
})

const sameAnswer = computed(() => {
  return (
    multiplayer.getters.firstDecision.value?.solution_id ===
    multiplayer.getters.advice.value?.solution_id
  )
})

const handleReject = () => {
  state.decisionGiven = true
  multiplayer.actions.setFinalDecisionStatus(FinalDecisionStatus.Rejected)
}

const handleAcceptAdvice = () => {
  state.decisionGiven = true
  multiplayer.actions.setFinalDecisionStatus(FinalDecisionStatus.AcceptedAdvice)
}

const handleAcceptFirstDecision = () => {
  state.decisionGiven = true
  multiplayer.actions.setFinalDecisionStatus(FinalDecisionStatus.Accepted)
}

const handleConfirm = () => {
  state.decisionGiven = true
  multiplayer.actions.setFinalDecisionStatus(FinalDecisionStatus.Confirmed)
}
</script>

<style scoped>
.bg-faded {
  background-color: rgba(0, 0, 0, 0.4);
}

.decision-wrapper {
  bottom: -8%;
}

.decision-wrapper button {
  width: 50%;
  padding: 0.75rem 0;
}
</style>
