<!-- Copyright 2022 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="fadein flex h-full w-full flex-col justify-between items-center">
    <div class="absolute orbitanimation">
      <div class="absolute w-6 h-6 bg-red-300 rounded-full animate-ping top-0 left-14"></div>
      <img class="w-20" src="@/assets/images/dashboard/spacecraft-i.png" />
    </div>
    <div class="absolute orbitanimation-rev">
      <img class="w-32" src="@/assets/images/dashboard/planet-i.png" />
    </div>

    <div v-if="state.avatarChooserIsVisible" class="absolute left-0 w-full h-full">
      <AvatarChooser :user="user" @update-avatar="updateAvatar" />
    </div>

    <div
      v-if="lackingDetails"
      class="flex flex-col items-center w-full h-full absolute bg-black z-101"
    >
      <div class="flex flex-col flex-grow justify-center items-center w-full h-full text-white">
        <div class="border border-slate-700 bg-slate-900 p-4 rounded-3xl flex flex-col w-1/2">
          <h1 class="text-5xl font-bold">{{ t('greeting') }}</h1>
          <p class="m-11">{{ t('lackingdetails') }}</p>
          <AnswerInput
            v-model="user.profile.ageGroup"
            v-model:valid="validators.ageGroup"
            mode="number"
            class="text-black"
            :label="t('agegroup')"
          />
          <MultipleChoiceInput
            v-model="user.profile.languagesSpoken"
            mode="multiChoice"
            :options="userLanguages"
            class="w-30 mt-11 mb-5"
            :label="t('firstlanguage')"
          />
          <Button
            class="place-self-center m-4"
            :backgroundcolour="'bg-green-400'"
            :disabled="!allValid"
            @click="userDetailsSaved()"
            >{{ t('save') }}</Button
          >
        </div>
      </div>
    </div>

    <div v-else class="z-50 flex items-center justify-center flex-col w-3/4 h-4/5">
      <div class="w-full flex items-start flex-col text-white h-1/3">
        <h1 class="text-5xl mb-3 w-3/4 text-left">
          {{ `${t('welcome')} ${user.profile.username}!` }}
        </h1>
        <p class="border rounded-full p-4 w-full flex justify-between border-slate-700">
          {{ t('clicktostart') }}
        </p>
        <AvatarVue class="relative bottom-32 left-3/4 w-40" :avatar-ref="user.avatar.ref" />
      </div>

      <div
        v-if="mpGame && episodeIsAvailable"
        class="w-1/2 border rounded-2xl"
        :style="boxBackground(GameType.MP)"
      >
        <div
          :id="`div-selectGame-${mpGame.profile.name}`"
          class="cursor-pointer rounded-3xl flex flex-col items-center p-11 bg-black box-overlay"
        >
          <div class="mt-20">
            <p class="text-white font-semibold text-3xl">
              {{ t('multiplayer') }}
            </p>
            <p class="text-slate-500">
              {{ mpGame.profile.name }}
            </p>
          </div>
          <Button
            class="w-56 border-4 flex justify-center items-center cursor-pointer relative top-20 mb-2 uppercase"
            :childclass="'text-2xl w-1/2'"
            :backgroundcolour="'bg-black'"
            :bordercolour="'border-green-400'"
            :textcolour="'text-green-400'"
            :roundedcorners="true"
            @click="start(mpGame)"
            @touchstart.prevent="start(mpGame)"
          >
            {{ t('start') }}
          </Button>
        </div>
      </div>

      <div
        v-else-if="spGame && episodeIsAvailable"
        class="w-1/2 border rounded-2xl"
        :style="boxBackground(GameType.SP)"
      >
        <div
          :id="`div-selectGame-${spGame.profile.name}`"
          class="cursor-pointer rounded-3xl flex flex-col items-center p-11 bg-black box-overlay"
        >
          <div class="mt-20">
            <p class="text-white font-semibold text-3xl">
              {{ t('singleplayer') }}
            </p>
            <p class="text-slate-500">
              {{ spGame.profile.name }}
            </p>
          </div>
          <Button
            class="w-56 border-4 flex justify-center items-center cursor-pointer relative top-20 mb-2 uppercase"
            :childclass="'text-2xl w-1/2'"
            :backgroundcolour="'bg-black'"
            :bordercolour="'border-green-400'"
            :textcolour="'text-green-400'"
            :roundedcorners="true"
            @click="start(spGame)"
            @touchstart.prevent="start(spGame)"
          >
            {{ t('start') }}
          </Button>
        </div>
      </div>

      <div v-else class="w-1/2 border rounded-2xl" :style="boxBackground(GameType.SP)">
        <div
          class="cursor-pointer rounded-3xl flex flex-col items-center p-11 bg-black box-overlay"
        >
          <p class="text-white font-semibold text-3xl">
            {{ t('nogameavailable') }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex w-full justify-center h-1/5">
      <AvatarVue class="w-44" avatar-ref="KM_pet.svg" />
      <div class="bottom-5 flex flex-row items-center justify-between py-2 button-wrapper">
        <Button
          v-if="hasMonitorRole"
          backgroundcolour="bg-slate-700"
          bordercolour="border-transparent"
          textcolour="text-white"
          :roundedcorners="true"
          @click="visitMonitor()"
          @touchstart.prevent="visitMonitor()"
        >
          <img class="p-2 w-12 invert mr-2" :src="MonitorIcon" alt="monitor" />
          <p>{{ t('monitor') }}</p>
        </Button>
        <Button
          :style="`border-color: ${color.actions.selectColor(user.profile.username)};`"
          backgroundcolour="bg-slate-700"
          textcolour="text-white"
          :roundedcorners="true"
          @click="state.avatarChooserIsVisible = !state.avatarChooserIsVisible"
          @touchstart.prevent="state.avatarChooserIsVisible = !state.avatarChooserIsVisible"
        >
          <AvatarVue class="w-12 p-2 mr-2" :avatar-ref="user.avatar.ref" />
          <p>{{ t('chooseavatar') }}</p>
        </Button>
        <Button
          bordercolour="border-transparent"
          backgroundcolour="bg-slate-700"
          textcolour="text-white"
          childclass="w-48"
          :roundedcorners="true"
          @click="restart()"
          @touchstart.prevent="restart()"
        >
          <img class="p-2 w-12 invert mr-2" :src="ReloadIcon" alt="log out" />
          <p>{{ t('tryAgain') }}</p>
        </Button>
        <Button
          bordercolour="border-transparent"
          backgroundcolour="bg-slate-700"
          textcolour="text-white"
          childclass="w-36"
          :roundedcorners="true"
          @click="logout()"
          @touchstart.prevent="logout()"
        >
          <img class="p-2 w-12 invert mr-2" :src="LogoutIcon" alt="log out" />
          <p>{{ t('logout') }}</p>
        </Button>
      </div>
    </div>
    <!-- Footer with version number -->
    <div class="absolute bottom-2 right-2 text-xs text-gray-500">Version 3.0.27</div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, ref, Ref } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { Game, GameType, Group, User } from '@/models/main'
import { USER_ROLE } from '@/constants'

/* Stores */
import useAppStore from '@/store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useDeviceService from '@/composition/useDevice'
import useColorStore from '@/composition/colors'
import useParcelStore from '@/composition/parcel'
import useCMSStore from '@/store/useCMSStore'
import useGameStore from '@/store/useGameStore'
import useStateService from '@/composition/useState'
import useMultiPlayerState from '@/composition/useMultiplayerState'

/* Icons & Images */
import MonitorIcon from '@/assets/icons/fontawesome/flask-gear-regular.svg'
import LogoutIcon from '@/assets/icons/fontawesome/right-from-bracket-solid.svg'
import ReloadIcon from '@/assets/icons/fontawesome/arrows-rotate-solid.svg'
import Planet from '@/assets/icons/svg/planet_1.svg'

/* Components */
import MultipleChoiceInput from '@/components/base/MultipleChoiceInput.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'
import Button from '@/components/base/Button.vue'
import AvatarChooser from '@/components/dashboard/AvatarChooser.vue'
import AvatarVue from '@/components/base/Avatar.vue'
import { Episode } from '@/models/navigationModels'

const { actions: deviceActions } = useDeviceService()
const { actions: appActions } = useAppStore()
const { actions: userActions, getters: userGetters } = useUserStore()
const { actions: gameActions } = useGameStore()

const messages = {
  no: {
    tryAgain: 'Last på nytt'
  },
  sv: {
    tryAgain: 'Last på nytt'
  },
  en: {
    tryAgain: 'Reload'
  }
}

interface GroupShareOptionListItem {
  title: string
  id: string
}
interface LanguageOptionListItem {
  title: string
  id: string
}

const parcelStore = useParcelStore()
const router = useRouter()
const color = useColorStore()
const appStore = useAppStore()
const cmsStore = useCMSStore()
const gameStore = useGameStore()
const userStore = useUserStore()
const stateService = useStateService()
const multiplayer = useMultiPlayerState()

const usersGroups: Ref<GroupShareOptionListItem[]> = ref([])
const userLanguages: Ref<LanguageOptionListItem[]> = ref([])
const validators: Ref<Record<string, boolean>> = ref({})
const lackingDetails = ref(false)

const { t } = useI18n({ messages })

const state = reactive({
  avatarChooserIsVisible: false
})

const allValid = computed(() => {
  return Object.values(validators.value).every((v) => v)
})

const mpGame = computed(() => {
  return gameStore.getters.games.value.filter(
    (g: Game) => g.details.participants.includes(user.value._id) && !g.details.dyadSplit
  )[0]
})

const spGame = computed(() => {
  return gameStore.getters.games.value.filter((g: Game) => g.details.gameType === GameType.SP)[0]
})

const episodeIsAvailable = computed(() => {
  return (
    cmsStore.getters.selectedActivity.value.activity?.episodes.filter((e: Episode) => !e.completed)
      .length !== 0
  )
})

const user = computed(() => {
  return userGetters.myUser.value
})

const hasMonitorRole = computed(() =>
  userActions.hasMinimumRole(userGetters.myUser.value, USER_ROLE.monitor)
)

onMounted(async () => {
  multiplayer.actions.reset()
  multiplayer.actions.resetSync()

  // Save media cache here, so it runs after returning from a Question series
  // Yet to determine if this is the best position to do this
  deviceActions.saveMediaCache()
  await userActions.getMyUser()
  const user = userGetters.myUser.value
  if (user._id) {
    await userActions.saveData()
    if (user.avatar.name === '' && user.profile.role === USER_ROLE.user)
      state.avatarChooserIsVisible = true
    lackingDetails.value =
      user.profile.username === 'username unknown' ||
      user.profile.languagesSpoken.length === 0 ||
      !user.profile.ageGroup
    usersGroups.value = user.groups.map((g: Group) => {
      return {
        id: g._id,
        title: g.name
      }
    })

    userLanguages.value = [
      { title: 'Norsk', id: 'norsk' },
      { title: 'Annet språk', id: 'annet' }
    ]
    await gameActions.getGames('', user._id, true) // get games for current user only...
  } else router.push('/')
})

const restart = () => {
  cmsStore.actions.resetStorage()
  router.push(`/postlogin`)
}

const updateAvatar = () => {
  userActions.getMyUser().then(() => (state.avatarChooserIsVisible = false))
}

const visitMonitor = () => {
  router.push('/monitor')
}

const userDetailsSaved = () => {
  userStore.actions.updateUser(user.value).then((user: User | undefined) => {
    if (user) {
      userStore.actions.selectUser(user)
      lackingDetails.value = false
    }
  })
}

const logout = async () => {
  const closeMqtt = async () => {
    const userId = user.value._id
    const games = gameStore.getters.games.value.filter((game: Game) =>
      game.details.participants.includes(userId)
    )
    for (const game of games) parcelStore.actions.unSubscribeTopic(game._id)
    parcelStore.actions.unSubscribeTopic(userId)
    await parcelStore.actions.closeMQTTConnection()
  }

  await closeMqtt()
  await appActions.logout(false)
  router.push('/')
}

const start = async (game: Game) => {
  multiplayer.actions.reset()
  multiplayer.actions.resetSync()

  await userStore.actions.getMyUser()

  const detailedGame = await gameStore.actions.getGameDetails(game._id)
  gameStore.actions.selectGame(detailedGame)
  cmsStore.actions.setActivityID(detailedGame.details.currentActivityId) // set game activity id
  await cmsStore.actions.getSets(appStore.getters.languageCode.value)

  // sorting the shuffled items...
  cmsStore.actions.sortActivity(detailedGame.details.shuffleDetails)

  // set details & begin game
  stateService.actions.setEpisodeDetailsFromDashboard()
  stateService.actions.begin()
}

const boxBackground = (gameType: GameType) => {
  const backgroundImage = gameType === GameType.MP ? Planet : Planet // non-MP could have a different background image
  return `background-image: url(${backgroundImage}); background-repeat: no-repeat; background-position: center; background-size: 80%; margin-right:${
    gameType === GameType.MP && spGame.value ? 3 : 0
  }rem;`
}
</script>

<style scoped>
.button-wrapper button {
  height: 70px;
  margin: 0 5px;
}

.box-overlay {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
