<!-- Copyright 2023 Richard Nesnass, Tom Bjarne Seidel

 This file is part of SL+.

 SL+ is free software: you can redistribute it and/or modify
 it under the terms of the GNU Affero General Public License as published by
 the Free Software Foundation, either version 3 of the License, or
 (at your option) any later version.

 GPL-3.0-only or GPL-3.0-or-later

 SL+ is distributed in the hope that it will be useful,
 but WITHOUT ANY WARRANTY; without even the implied warranty of
 MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License
 along with SL+.  If not, see http://www.gnu.org/licenses/. -->
<template>
  <div class="flex flex-col h-full justify-center items-center">
    <img class="animate-pulse flex w-full mb-20" src="@/assets/images/km-logo-big.png" />

    <div v-if="!readyToSelectLogin" class="flex justify-center items-center h-1/4">
      <img
        class="absolute jitter-rev z-50 flex w-1/4 max-w-xs ml-11 mt-11"
        src="@/assets/images/dashboard/spacecraft-ii.png"
      />
      <img
        class="jitter absolute flex w-16 left-1/4"
        src="@/assets/images/dashboard/spacecraft-i.png"
      />
      <img
        class="jitter-rev absolute flex w-16 right-1/4"
        src="@/assets/images/dashboard/planet-i.png"
      />
    </div>

    <div v-if="readyToSelectLogin" class="bg-white-alt w-1/2 p-7 rounded-2xl">
      <div class="flex flex-col items-center">
        <div v-if="mode === ''" class="flex w-full flex-col">
          <Button
            :backgroundcolour="''"
            :textcolour="'text-green-600'"
            :bordercolour="'border-green-600'"
            :childclass="'w-full'"
            @click="mode = 'userPassLogin'"
            >{{ t('loginwithpassword') }}</Button
          >
          <!-- FEIDE Login Button -->
          <Button
            class="mt-7"
            :backgroundcolour="''"
            :textcolour="'text-blue-600'"
            :bordercolour="'border-blue-600'"
            :childclass="'w-full'"
            @click="
              settings.feideLoginPasswordToggle ? (showFeidePasswordDialog = true) : DPlogin()
            "
          >
            {{ t('loginwithfeide') }}
          </Button>

          <!-- SMS Login Button -->
          <Button
            class="mt-7"
            :backgroundcolour="''"
            :textcolour="'text-purple-600'"
            :bordercolour="'border-purple-600'"
            :childclass="'w-full'"
            @click="
              settings.smsLoginPasswordToggle ? (showSmsPasswordDialog = true) : (mode = 'smsLogin')
            "
          >
            {{ t('loginwithsms') }}
          </Button>
        </div>

        <!-- FEIDE Password Dialog -->
        <div
          v-if="showFeidePasswordDialog"
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div class="bg-white p-5 rounded-lg w-2/3 max-w-lg text-center">
            <p>{{ t('enterPassword') }}</p>
            <input type="password" v-model="enteredFeidePassword" class="border p-2 w-full mt-2" />
            <Button
              class="mt-4"
              :backgroundcolour="''"
              :textcolour="'text-green-400'"
              @click="checkFeidePassword"
            >
              OK
            </Button>
            <Button
              class="mt-4"
              :backgroundcolour="''"
              :textcolour="'text-red-400'"
              @click="showFeidePasswordDialog = false"
            >
              {{ t('cancel') }}
            </Button>
          </div>
        </div>

        <!-- SMS Password Dialog -->
        <div
          v-if="showSmsPasswordDialog"
          class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
        >
          <div class="bg-white p-5 rounded-lg w-2/3 max-w-lg text-center">
            <p>{{ t('enterPassword') }}</p>
            <input type="password" v-model="enteredSmsPassword" class="border p-2 w-full mt-2" />
            <Button
              class="mt-4"
              :backgroundcolour="''"
              :textcolour="'text-green-400'"
              @click="checkSmsPassword"
            >
              OK
            </Button>
            <Button
              class="mt-4"
              :backgroundcolour="''"
              :textcolour="'text-red-400'"
              @click="showSmsPasswordDialog = false"
            >
              {{ t('cancel') }}
            </Button>
          </div>
        </div>

        <div v-if="mode === 'feide'" class="flex flex-col place-content-center w-full">
          <Button
            v-if="filteredLocalUsersWithExpiry.length > 0"
            id="button-pinlogin"
            @click="PINlogin()"
            >{{ t('loginRemembered') }}</Button
          >
        </div>

        <div v-else-if="mode === 'userPassLogin'" class="flex flex-col place-content-center w-full">
          <div class="flex flex-col justify-center place-items-center">
            <AnswerInput
              v-model="username"
              v-model:valid="validators.usename"
              mode="text"
              :placeholder="t('enterUsername')"
              class="w-full"
              :border="true"
            ></AnswerInput>
            <AnswerInput
              v-model="password"
              v-model:valid="validators.password"
              class="mt-2 w-full"
              mode="password"
              :placeholder="t('enterPassword')"
              :border="true"
              @keyup.enter="sendUsernamePassword()"
            ></AnswerInput>
            <Button
              class="mt-3 w-full capitalize"
              :textcolour="'text-white'"
              :backgroundcolour="'bg-green-500'"
              :bordercolour="''"
              :childclass="'w-full'"
              @click="sendUsernamePassword()"
              >{{ t('send') }}
            </Button>
          </div>
        </div>

        <div
          v-else-if="mode === 'pin-user'"
          class="flex flex-col items-center h-full justify-center w-full"
        >
          <p class="text-white">Log in as</p>
          <div
            v-for="u in filteredLocalUsersWithExpiry"
            :key="u._id"
            class="mt-8 rounded-full w-full bg-white flex flex-col items-center p-4"
            @click="selectUser(u)"
          >
            <p>{{ u.name }}</p>
            <p class="text-xs">Expires in {{ u.expiresIn.value }}</p>
          </div>
        </div>

        <div
          v-else-if="mode === 'smsLogin'"
          key="landing-mobil"
          class="flex flex-col items-center h-full justify-center pb-12"
        >
          <div class="flex flex-col place-items-center">
            <AnswerInput
              v-model="mobileNumber"
              v-model:valid="validators.mobil"
              mode="tel"
              :label="t('enterMobilNumber')"
              :border="true"
              @keyup.enter="sendPhoneNumber()"
            ></AnswerInput>
            <Button
              id="button-send"
              class="mt-10 capitalize"
              background-class="bg-green-highlight"
              @click="sendPhoneNumber()"
              >{{ t('send') }}
            </Button>
          </div>
        </div>
        <div
          v-else-if="mode === 'smsCode'"
          key="landing-otc"
          class="flex flex-col items-center h-full justify-center pb-12"
        >
          <div class="flex flex-col">
            <AnswerInput
              v-model:valid="validators.mobil"
              v-model="otc"
              class="m-2"
              mode="otc"
              :label="t('enterMobilOTC')"
              :border="true"
              @enterkey="sendOTC()"
            ></AnswerInput>
            <Button
              id="button-send"
              class="mt-16"
              background-class="bg-green-highlight"
              @click="sendOTC"
              >{{ t('send') }}
            </Button>
            <Button
              id="button-back"
              class="my-2"
              background-class="bg-grey"
              text-class="text-black"
              @click="() => ((pinCode = ''), (otc = ''), (mode = 'smsLogin'))"
              >{{ t('back') }}
            </Button>
          </div>
        </div>

        <Button
          class="mt-7"
          :backgroundcolour="''"
          :textcolour="'text-red-600'"
          :bordercolour="'border-red-600'"
          :childclass="'w-full mx-7'"
          @click="resetUIState()"
          >{{ t('back') }}</Button
        >
      </div>
    </div>
    <!-- Start Game Button -->
    <div v-else class="flex flex-row items-center pt-4 mt-11">
      <Button
        class="uppercase text-xl"
        :backgroundcolour="''"
        :textcolour="'text-green-400'"
        :bordercolour="'border-green-400'"
        :disabled="(settings && settings.globalMessageToggle) || !isGlobalSettingsFetched"
        @click="readyToSelectLogin = true"
      >
        {{ t('startgame') }}
      </Button>
    </div>

    <!-- Message Dialog -->
    <div
      v-if="settings && settings.globalMessageToggle"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div class="bg-white p-5 rounded-lg w-2/3 max-w-lg text-center">
        <p v-html="settings.globalMessage.replace(/\n/g, '<br>')"></p>
        <Button
          class="mt-4"
          :backgroundcolour="''"
          :textcolour="'text-green-400'"
          @click="settings.globalMessageToggle = false"
        >
          OK
        </Button>
      </div>
    </div>

    <!-- Password Dialog -->
    <div
      v-if="showGlobalPasswordDialog"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <div class="bg-white p-5 rounded-lg w-2/3 max-w-lg text-center">
        <p>{{ t('enterPassword') }}</p>
        <input type="password" v-model="enteredGlobalPassword" class="border p-2 w-full mt-2" />
        <Button
          class="mt-4"
          :backgroundcolour="''"
          :textcolour="'text-green-400'"
          @click="checkGlobalPassword"
        >
          OK
        </Button>
      </div>
    </div>

    <div class="absolute top-0 left-0 p-4 text-center text-white w-full">
      <p>{{ message }}</p>
    </div>
    <p class="absolute bottom-0 right-0 text-xs m-2">v{{ appVersion }}</p>
    <!-- Footer with version number -->
    <div class="absolute bottom-2 right-2 text-xs text-gray-500">Version 3.0.27</div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref, onBeforeUnmount, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import moment from 'moment'

import Button from '@/components/base/Button.vue'
import AnswerInput from '@/components/base/AnswerInput.vue'

import { baseUrl, appVersion, LanguageCodes } from '@/constants'
import { LocalUser } from '../../models/main'

import useAppStore from '../../store/useAppStore'
import useUserStore from '@/store/useUserStore'
import useSettingsStore from '@/store/useSettingsStore'

const jwtExpiryConstant = (import.meta.env.VITE_JWT_EXPIRY || '0') as string
const jwtExpiry = Number.parseInt(jwtExpiryConstant)

const messages = {
  no: {
    cancel: 'Avbryt',
    startgame: 'Start spillet',
    incorrectPassword: 'Feil passord',
    errorFetchingSettings: 'Kunne ikke hente innstillinger',
    loginIDPorten: 'Logg inn med FEIDE',
    loginRemembered: 'Logg inn med PIN',
    enterMobilNumber: 'Mobilnummer',
    enterUsername: 'Ditt brukernavn',
    enterPassword: 'Ditt passord',
    enterMobilOTC: 'Engangskode (fra SMS)',
    invalidEntry: 'Mangler mobilnummer eller passord',
    phoneNumberError: 'Error med nummer',
    'User not found': 'Bruker ikke funnet',
    'LOGIN: Incorrect password': 'Feil passord',
    userPasswordLoginError: 'Feil brukernavn eller passord',
    'wrong otc': 'Feil engangspassord',
    'sms login accepted': 'Det kan ta litt tid før du får engangskode med SMS.'
  },
  sv: {
    cancel: 'Kansellere',
    loginIDPorten: 'Logga in med FEIDE',
    loginRemembered: 'Logga in med PIN',
    enterMobilNumber: 'Mobilnummer',
    enterUsername: 'Ditt användarnamn',
    enterPassword: 'Ditt lösenord',
    enterMobilOTC: 'Engångskod (från SMS)',
    invalidEntry: 'Saknar mobilnummer eller lösenord',
    phoneNumberError: 'Fel med nummer',
    'User not found': 'Användaren hittades inte',
    'LOGIN: Incorrect password': 'Fel lösenord',
    userPasswordLoginError: 'felaktigt användarnamn eller lösenord',
    'wrong otc': 'Felaktigt engångslösenord',
    'sms login accepted': 'Det kan ta lite tid innan du får en engångskod via SMS.'
  },
  en: {
    cancel: 'Cancel',
    startgame: 'Start game',
    incorrectPassword: 'Incorrect password',
    errorFetchingSettings: 'Failed to fetch settings',
    loginIDPorten: 'Log in with FEIDE',
    loginRemembered: 'Log in with PIN',
    enterMobilNumber: 'Mobile phone number',
    enterUsername: 'Your username',
    enterPassword: 'Your password',
    enterMobilOTC: 'One time code (from SMS)',
    invalidEntry: 'Invalid number or password',
    phoneNumberError: 'Error processing phone number',
    userPasswordLoginError: 'Incorrect username or password',
    'User not found': 'User not found',
    'LOGIN: Incorrect password': 'Incorrect password',
    'wrong otc': 'Wrong one time code',
    'sms login accepted': 'It may take some time before you get the one time code by SMS.'
  }
}

interface LocalUserWithExpiry extends LocalUser {
  expiresIn: Ref<string>
  valid: Ref<boolean>
}
const router = useRouter()

const { t, locale } = useI18n({ messages })
const { getters: appGetters, actions: appActions } = useAppStore()
const userStore = useUserStore()
const { actions: settingsActions, getters: settingsGetters } = useSettingsStore()

const mode = ref('')
const username = ref('')
const password = ref('')
const otc = ref('')
const pinCode = ref('')
const message = ref('')
const mobileNumber = ref('')
const showTestLogin = ref(false)
const selectedUser = ref()
const loginInProgress = ref(false)
const validators: Ref<Record<string, boolean>> = ref({})
const readyToSelectLogin: Ref<boolean> = ref(false)

// const showPasswordOption = import.meta.env.VITE_MQTT_HOST === 'localhost'
let timer: ReturnType<typeof setTimeout>
const localUsers = Object.values(appGetters.persistedLocalUsers.value)
const localUsersWithExpiry: LocalUserWithExpiry[] = localUsers.map((lu) => ({
  ...lu,
  expiresIn: ref(''),
  valid: ref(false)
}))

const settings = settingsGetters.settings

//Related to global password
const showGlobalPasswordDialog = computed(() => {
  return (
    settings.value &&
    settings.value.globalPasswordToggle &&
    showGlobalPasswordToggle.value &&
    !settings.value.globalMessageToggle
  )
})
const enteredGlobalPassword = ref('')

//Related to global settings dependent FEIDE and SMS password
const isGlobalSettingsFetched = ref(false)
const showFeidePasswordDialog = ref(false)
const showSmsPasswordDialog = ref(false)
const showGlobalPasswordToggle = ref(false)
const enteredFeidePassword = ref('')
const enteredSmsPassword = ref('')

onMounted(async () => {
  await settingsActions.fetchSettings()
  if (Object.keys(LanguageCodes).some((language) => language == locale.value)) {
    const lang: LanguageCodes = locale.value as LanguageCodes
    appActions.setLanguageCode(lang)
  } else appActions.setLanguageCode(LanguageCodes.no)

  if (router.currentRoute.value.params.admin === 'admin') {
    mode.value = 'login'
  }
  if (userStore.getters.myUser.value._id) router.push('/dashboard') // default to dashboard, it will fetch the user and navigate back to this screen

  try {
    if (settings.value) {
      isGlobalSettingsFetched.value = true
      showGlobalPasswordToggle.value = settings.value.globalPasswordToggle
    } else {
      console.error('Settings not found')
    }
  } catch (error) {
    alert(t('errorFetchingSettings'))
  }
})

showTestLogin.value = false

function PINlogin() {
  mode.value = 'pin-user'
}

function DPlogin(testing = false, username = 'testuser') {
  if (loginInProgress.value) return
  if (filteredLocalUsersWithExpiry.value.length === 0) {
    let feideLoginUrl = `${baseUrl}/auth/dataporten/login`
    // If using Cordova, activate the cordova-oauth plugin
    const testingString = testing ? `&testing=${username}` : ''
    if (window.cordova) {
      feideLoginUrl += `?&client=mobileApp&appVersion=${appVersion}&remember=true${testingString}`
      window.OAuth(feideLoginUrl, 'oauth:dataporten', undefined, (success) => {
        console.log(success)
      })
    } else {
      feideLoginUrl += `?&appVersion=${appVersion}&client=webApp${testingString}`
      window.location.href = feideLoginUrl
    }
  }
}

const allValid = computed(() => {
  return Object.values(validators.value).every((v) => v)
})

function feedbackMessage(m: string) {
  clearTimeout(timer)
  message.value = m
  timer = setTimeout(() => {
    message.value = ''
  }, 1500)
}

function resetUIState() {
  if (mode.value === '') readyToSelectLogin.value = false
  else mode.value = ''
}

function selectUser(u: LocalUser) {
  localUsers.forEach((user) => (user.selected = false))
  u.selected = true
  selectedUser.value = u
  mode.value = 'pp'
}

async function sendUsernamePassword() {
  if (loginInProgress.value) return
  if (!allValid.value || !username.value || !password.value) {
    feedbackMessage(t('invalidEntry'))
  } else {
    appActions
      .userPasswordLogin(username.value, password.value)
      .then(() => {
        setTimeout(() => router.push('/postlogin'), 1000)
      })
      .catch((error) => {
        console.log(error)
        loginInProgress.value = false
        return feedbackMessage(t('userPasswordLoginError'))
      })
  }
}

async function sendPhoneNumber() {
  if (loginInProgress.value) return
  if (!allValid.value || !mobileNumber.value) {
    feedbackMessage(t('invalidEntry'))
  } else {
    loginInProgress.value = true
    try {
      await appActions.smsLogin(mobileNumber.value)
    } catch (error) {
      console.log(error)
      return feedbackMessage(t('phoneNumberError'))
    }
    mode.value = 'smsCode'
    feedbackMessage(t('sms login accepted'))
    loginInProgress.value = false
  }
}

async function sendOTC() {
  if (loginInProgress.value) return
  if (!allValid.value || !otc.value) {
    feedbackMessage(t('invalidEntry'))
  } else {
    loginInProgress.value = true
    let smsLoginUrl = `${baseUrl}/auth/sms/code`
    if (window.cordova) {
      smsLoginUrl += `?&client=mobileApp&appVersion=${appVersion}&remember=true&otc=${otc.value}&mobil=${mobileNumber.value}`
      window.OAuth(smsLoginUrl, 'oauth:dataporten', undefined, (success) => {
        console.log(success)
        loginInProgress.value = false
      })
    } else {
      smsLoginUrl += `?&appVersion=${appVersion}&client=webApp&otc=${otc.value}&mobil=${mobileNumber.value}`
      window.location.href = smsLoginUrl
    }
  }
}

const calculateCountdown = () => {
  const dateNow = new Date()
  localUsersWithExpiry.forEach((lu: LocalUserWithExpiry) => {
    const countdown = jwtExpiry - moment(dateNow).diff(lu.lastLogin, 'seconds')
    lu.expiresIn.value = moment.duration(countdown, 'seconds').humanize()
    lu.valid.value = countdown > 0
  })
}
calculateCountdown()
const countDownInterval = setInterval(() => {
  calculateCountdown()
}, 10000)
onBeforeUnmount(() => {
  clearInterval(countDownInterval)
})

const filteredLocalUsersWithExpiry = computed(() =>
  localUsersWithExpiry.filter((u) => u.valid.value)
)

function checkGlobalPassword() {
  if (enteredGlobalPassword.value === settings.value.globalPassword) {
    readyToSelectLogin.value = true
    showGlobalPasswordToggle.value = false
  } else {
    alert(t('incorrectPassword')) // Add to messages
  }
}
function checkFeidePassword() {
  if (enteredFeidePassword.value === settings.value.feideLoginPassword) {
    showFeidePasswordDialog.value = false
    DPlogin()
  } else {
    alert(t('incorrectPassword'))
  }
}

function checkSmsPassword() {
  if (enteredSmsPassword.value === settings.value.smsLoginPassword) {
    showSmsPasswordDialog.value = false
    mode.value = 'smsLogin'
  } else {
    alert(t('incorrectPassword'))
  }
}
</script>

<style scoped></style>
